import React, { useCallback, useEffect, useState, useRef, useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";
import Question from "./Question";
import SurveyNav from "./SurveyNav";
import surveyResponseService from "../services/SurveyResponseService";
import { flatten } from "flatten-anything";
import { useParams, useNavigate } from "react-router-dom";
import { PageContext } from "../context/PageContext";
import { AppContext } from "../context/AppContext";
import { isMobile } from "react-device-detect";

import logo from "../assets/active-comply-logo.svg";

import emaptaLogo from "../assets/emapta.png";
import tmacLogo from "../assets/tmac.png";
import loancareLogo from "../assets/loancare.png";

import { deleteField } from "firebase/firestore";
import NavHeader from "./NavHeader";

// This function works recursively. It helps sets the values for the forword and back buttons
// It keeps iterating forward one page at a time to determine if it
// should render or skip. It goes in the opposite direction if isForword
// is set to false(for the privous page button)
const getPageNavIndex = (isForword, currentPageIndex, survey, answersCollection) => {
  if (isForword) {
    currentPageIndex++;
  } else {
    currentPageIndex--;
  }

  const dependsOn = survey?.pages[survey.pageOrder[currentPageIndex]].pageDependsOn;

  if (!dependsOn) {
    return currentPageIndex;
  }

  let [pageId, questionId] = Object.keys(dependsOn)[0].split(".");

  let answerPage = answersCollection[pageId];
  if (!answerPage) {
    return currentPageIndex;
  } else {
    //this is problematic this field looks different depending on how its populated
    const valueOfDependent = answerPage?.["data." + questionId] ?? answerPage?.data?.[questionId] ?? null;
    // we now know that this page should not render becouse a 'valueOfDependent' exists.
    // unless they answer the qustion 'correctly' that it depends on

    var dependsOnObject = dependsOn[Object.keys(dependsOn)];

    if (dependsOnObject && dependsOnObject.value && dependsOnObject.value === valueOfDependent) {
      return currentPageIndex;
    }

    if (dependsOnObject.values) {
      for (const v of dependsOnObject.values) {
        if (v === valueOfDependent) {
          return currentPageIndex;
        }
      }
    }
    return getPageNavIndex(isForword, currentPageIndex, survey, answersCollection);
  }
};

function Page(props) {
  const { page, survey, surveyResponse, linkedDevice } = props;

  const [pageState] = useContext(PageContext);
  const [appState] = useContext(AppContext);

  const [readyToRender, setReadyToRender] = useState(false);
  const [nextPageId, setNextPageId] = useState();
  const [previousPageId, setPreviousPageId] = useState();
  const [isPageCompleted, setIsPageCompleted] = useState(false);
  const [isAnswerLoaded, setIsAnswerLoaded] = useState(false);

  const [isWelomeAndDesktopOnly, setIsWelomeAndDesktopOnly] = useState(false);

  const [answersCollection, setAnswersCollection] = useState({});

  const pageNumber = survey.pageOrder.indexOf(page.id) + 1;
  const pageCount = survey.pageOrder.length;
  const progressText = `${pageNumber} of ${pageCount}`;
  const progressPercentage = Math.round((pageNumber / pageCount) * 100);

  // Define a configuration object mapping partial survey IDs to logos
  const logoConfig = {
    roi_emapta: emaptaLogo,
    roi_tmac: tmacLogo,
    roi_loancare_nmls: loancareLogo,
    roi_loancare_non_nmls: loancareLogo,
  };

  // Function to find the logo based on partial match of survey ID
  function findLogo(surveyId) {
    for (const partialId in logoConfig) {
      if (surveyId.startsWith(partialId)) {
        return logoConfig[partialId];
      }
    }
    return logo; // Default logo if no match is found
  }

  // Determine which logo to use based on the survey ID
  const logoToUse = findLogo(survey.id);

  const answers = useRef({});
  const params = useParams(); // url params
  let navigate = useNavigate();

  const getQuestionProps = (questionId) => {
    return {
      question: survey.questions[questionId],
      setAnswerData: setAnswerData,
      surveyResponse: surveyResponse,
      linkedDevice: linkedDevice,
    };
  };

  useEffect(() => {
    document.title = page?.text?.pageTitle ? page.text.pageTitle : page.id;

    //when we are on a new page the laptop could have diffrent local copy of the answers then the phone or vice versa.
    //We need to synchronized it becouse the next and previos page button depend on it.
    surveyResponseService.getAnswers(surveyResponse.id).then((a) => {
      setAnswersCollection(a);
    });
  }, [page]);

  useEffect(() => {
    setIsAnswerLoaded(page.questionId === pageState.answer.id);

    const currentQuestion = survey.questions[page.id];
    const isWelomeAndDesktopOnly =
      currentQuestion?.type === "info" &&
      currentQuestion?.id === "welcome" &&
      currentQuestion?.deviceType === "desktop";

    setIsWelomeAndDesktopOnly(isWelomeAndDesktopOnly);
  }, [page, pageState, survey.questions]);

  useEffect(() => {
    if (!page) {
      return;
    }

    let currentPageId = page.id;

    let currentPageOrderIndex = survey.pageOrder.indexOf(currentPageId);

    //set Previous page nav button
    if (currentPageOrderIndex - 1 >= 0) {
      setPreviousPageId(survey.pageOrder[getPageNavIndex(false, currentPageOrderIndex, survey, answersCollection)]);
    } else {
      setPreviousPageId(null);
    }

    //set Next page nav button
    if (currentPageOrderIndex + 1 < survey.pageOrder.length) {
      setNextPageId(survey.pageOrder[getPageNavIndex(true, currentPageOrderIndex, survey, answersCollection)]);
    } else {
      setNextPageId(null);
    }

    setReadyToRender(true);
  }, [answersCollection, page]);

  const handleAction = async (options) => {
    setReadyToRender(false);

    await saveAnswer();

    switch (options.navigate) {
      case "next":
        navigate(`/${params.surveyResponseId}/${params.tokenId}/${nextPageId}`, { state: "fromAction" });
        window.scrollTo(0, 0);
        break;
      case "previous":
        navigate(`/${params.surveyResponseId}/${params.tokenId}/${previousPageId}`, { state: "fromAction" });
        window.scrollTo(0, 0);
        break;
      default:
        console.log("Nowhere to go...");
        break;
    }
  };

  const saveAnswer = useCallback(async () => {
    // check for values that need to be propagated to the surveyResponse doc
    // step 1: loop over the answers being saved
    Object.keys(answers.current).forEach((questionId) => {
      //get the question def for this questionId
      const questionDef = survey.questions[questionId];

      if (questionDef && questionDef.mapToParent) {
        // mapToParent has been set in the survey def so let's do it
        let surveyResponseUpdate = {};
        let shouldSave = false;

        Object.keys(questionDef.mapToParent).forEach((answerKey) => {
          const parentKey = questionDef.mapToParent[answerKey];
          const currentAnswer = answers.current[questionId];
          const answerValue = currentAnswer[answerKey];

          if (answerValue) {
            // Prepare the data update. E.g. surveyResponse.data.lastName = <answerValue>
            shouldSave = true;
            surveyResponseUpdate[parentKey] = answerValue;
          }
        });

        if (shouldSave) {
          surveyResponseService.update(surveyResponse.id, surveyResponseUpdate);
        }
      }
    });

    await surveyResponseService.updateAnswers(surveyResponse.id, answers.current);

    answers.current = {};
  }, [answers, survey, surveyResponse]);

  const hasNextPage = () => {
    return nextPageId ? true : false;
  };

  const hasPreviousPage = () => {
    return previousPageId ? true : false;
  };

  const isLocked = () => {
    return surveyResponse.state?.locked;
  };

  const setAnswerData = useCallback(
    (questionId, answerData, save, options) => {
      // undefined values mean that the field should be deleted
      if (answerData.data) {
        Object.keys(answerData.data).forEach((key) => {
          if (answerData.data[key] === undefined) {
            answerData.data[key] = deleteField();
          }
        });
      }

      if (answerData.meta) {
        Object.keys(answerData.meta).forEach((key) => {
          if (answerData.meta[key] === undefined) {
            answerData.meta[key] = deleteField();
          }
        });
      }

      answerData.surveyResponseId = surveyResponse.id;
      answerData.surveyId = surveyResponse.surveyId;

      if (options && options.collectClientData) {
        answerData.userAgent = navigator.userAgent;
        answerData.clientIp = appState.clientIp;
        answerData.screen = { width: window.screen.width, height: window.screen.height };
        answerData.browser = { width: window.innerWidth, height: window.innerHeight };
      }

      answerData.lastModified = new Date().toISOString();

      answers.current[questionId] = flatten(answerData);

      setIsPageCompleted(answers.current[questionId].isComplete);

      if (save) {
        saveAnswer();
      }

      //added for https://activecomply.atlassian.net/browse/DEV-21 on 2/5/2025
      if (Object.keys(answersCollection).length === 0) {
        surveyResponseService.getAnswers(surveyResponse.id).then((a) => {
          setAnswersCollection({ ...a, ...answers.current });
        });
      } else {
        setAnswersCollection((prevAnswers) => ({ ...prevAnswers, ...answers.current }));
      }
    },
    [answers, saveAnswer, surveyResponse.id, surveyResponse.surveyId, appState.clientIp]
  );

  const getRows = () => {
    if (page.rows) {
      return page.rows;
    } else {
      return [{ questions: [page.questionId] }];
    }
  };

  const getColumnCount = () => {
    if (page.rows) {
      return page.rows.map((row) => row.questions).filter((question) => question.length > 1)[0]?.length || 1;
    } else {
      return 1;
    }
  };
  const numberOfColumns = getColumnCount();
  const numberOfRows = getRows().length;

  return (
    <>
      <NavHeader logoToUse={logoToUse} />

      <main id="page" className={`${pageState.darkBackground ? "bg-black" : ""}`}>
        <Container className={`rows-${numberOfRows}`} fluid="md">
          {readyToRender &&
            getRows().map((row, rowIndex) => (
              <Row key={rowIndex} className={`justify-content-center cols-${numberOfColumns}`}>
                {row.questions.map((questionId) => (
                  <Col lg={10} xl={9} xxl={9} key={questionId}>
                    <Question {...getQuestionProps(questionId)} />
                  </Col>
                ))}
              </Row>
            ))}
        </Container>
      </main>

      {isAnswerLoaded && pageState.showSurveyNav && !isLocked() && !(isWelomeAndDesktopOnly && isMobile) && (
        <SurveyNav
          showPrevious={hasPreviousPage()}
          showNext={hasNextPage()}
          handleAction={handleAction}
          isPageCompleted={isPageCompleted}
        />
      )}

      {readyToRender && (
        <ProgressBar
          className="rounded-0 progress-sm"
          variant="primary"
          now={progressPercentage}
          xlabel={`${progressText}`}
        />
      )}
    </>
  );
}

export default Page;
